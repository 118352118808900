<template>
<div class="pa-4">
    <div class="pa-10">
        <v-btn @click="startScan()" >Старт</v-btn>
        <v-btn @click="resetScan()" >Стоп</v-btn>
    </div>    
    <video id="video" width="300" height="300" style="border: 1px solid gray"></video>
    <div>Результат: {{result}}</div>
</div>
</template>

<script>
import {
    BrowserMultiFormatReader
} from '@zxing/library';

export default {
    components: {},
    data() {
        return {
            codeReader: undefined,
            result: ''
        }
    },
    computed: {},
    methods: {
        startScan() {
            this.codeReader
                .listVideoInputDevices()
                .then(videoInputDevices => {
                    videoInputDevices.forEach(device => {
                        console.log(`${device.label}, ${device.deviceId}`)
                        const firstDeviceId = videoInputDevices[0].deviceId;
                        this.codeReader
                            .decodeOnceFromVideoDevice(undefined, 'video')
                            .then(result => {
                                this.result = result.text
                            })
                            .catch(err => console.error(err));

                    })
                })
                .catch(err => console.error(err));
        },
        resetScan(){
            this.codeReader.reset()
            this.result = ''
        }
    },
    mounted() {
        this.codeReader = new BrowserMultiFormatReader()
    }
}
</script>

<style>
</style>
